<template>
        <defaultSec :title="'申请列表'">
            <keep-alive>
                <el-form :inline="true" :model="formParam" class="demo-form-inline select-wrap" size="mini">
                    <el-form-item label="类型">
                        <el-select v-model="formParam.optType" placeholder="请选择退换货/补货类型">
                            <el-option label="补货" :value="1"></el-option>
                            <el-option label="退换货" :value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="订单号">
                        <el-input v-model="formParam.orderNo" placeholder="请输入订单号" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="initList">查询</el-button>
                    </el-form-item>
                </el-form>
            </keep-alive>
            <tablePagination :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
                <el-table :data="tableList" border size="mini">
                    <el-table-column type="index" label="序号"></el-table-column>

                    <el-table-column prop="originOrderNo" label="原单号"></el-table-column>
                    <el-table-column prop="newOrderNo" label="新单号"></el-table-column>
                    <el-table-column prop="optType" label="类型">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.optType == 1" type="info">补发</el-tag>
                            <el-tag v-if="scope.row.optType == 2" type="warning">换货</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="申请状态">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.formStatus == 0" type="info">作废</el-tag>
                            <el-tag v-if="scope.row.formStatus == 1" type="warning">申请中</el-tag>
                            <el-tag v-if="scope.row.formStatus == 2" type="success">申请通过</el-tag>
                            <el-tag v-if="scope.row.formStatus == 3" type="danger">驳回</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="oldValue" label="原商品"></el-table-column>
                    <el-table-column prop="newValue" label="新商品"></el-table-column>
                    <el-table-column prop="auditUser" label="审批人"></el-table-column>
                    <el-table-column prop="auditTime" label="审批时间"></el-table-column>
                    <el-table-column prop="deliverDate" label="期望配送日期"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button-group>
<!--                                <el-button v-if="scope.row.optType==2 && scope.row.formStatus==2" type="warning" plain size="mini" @click="modifyDeliverDate(scope.row.id)">修改配送日期</el-button>-->
                                <el-button v-if="scope.row.formStatus==1" type="danger" plain size="mini" @click="withdrawApply(scope.row.id)">撤回</el-button>
                                <el-button v-if="scope.row.optType==2 && scope.row.formStatus==2" type="warning" plain size="mini" @click="createOrder(scope.row.id)">生成订单</el-button>
                            </el-button-group>
                        </template>
                    </el-table-column>
                </el-table>
            </tablePagination>
            <el-dialog title="修改配送日期" :visible.sync="showDialog2">
                <el-form label-width="120px">
                    <el-form-item label="配送日期">
                        <el-date-picker v-model="deliverDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="操作">
                        <el-button type="primary" @click="saveDeliverDate">保 存</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    export default {
        data() {
            return {
                showDialog: false,
                showDialog2: false,
                deliverApplyId:0,
                deliverDate:"",
                formParam:{
                    pageSize:15,
                    currentPage:1,
                    optType:1,
                    orderNo:''
                },
                tableList:[],
                total:0
            }
        },
        components:{
            defaultSec,tablePagination
        },
        mounted(){
            this.initList()
        },
        methods: {
            initList() {
                this.$apiGet('lechun-csms/apply/myApplyList',this.formParam).then(res => {
                    console.log(res)
                    this.total = res.total;
                    this.tableList = res.list;
                    console.log("scope.row",this.tableList);
                })
            },
            handlePage(page){
                this.formParam.currentPage = page;
                this.initList()
            },
            modifyDeliverDate(id){
                this.showDialog2 = true;
                this.deliverApplyId = id;
            },
            saveDeliverDate(){
                this.$apiGet('lechun-csms/apply/modifyDeliverDate',{"applyId":this.deliverApplyId,"deliverDate":this.deliverDate}).then(res => {
                    this.$message({
                        showClose: true,
                        message: '成功',
                        type: 'success'
                    });
                    this.showDialog2 = false;
                    this.deliverDate = "";
                    this.deliverApplyId = 0;
                    this.initList();
                });
            },
            withdrawApply(applyId){
                this.$confirm('撤回后将作废，确定撤回吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$apiGet('lechun-csms/apply/withdrawApply',{applyId: applyId}).then(res => {
                        this.$message({
                            showClose: true,
                            message: '成功',
                            type: 'success'
                        });
                        this.initList();
                    });
                }).catch(() => {

                });
            },
            createOrder(applyId){
                this.$confirm('请确定已经收到货物', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$apiGet('lechun-csms/apply/finishChange',{applyId: applyId}).then(res => {
                        this.$message({
                            showClose: true,
                            message: '成功',
                            type: 'success'
                        });
                        this.initList();
                    });
                }).catch(() => {

                });
            }
        }
    }
</script>
<style scoped>
.expand-title {
    width: 70px;
    color: #444548;
    line-height: 30px;
    text-align: right;
    margin-right: 10px;
    font-weight: 600;
}

.expand-flex {
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
</style>
